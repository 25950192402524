import FJConfig from '../config/FJConfig';
import FJCaptcha from '../../util/FJCaptcha';
import FJUtil from '../util/FJUtil';
import $ from 'jquery';

const ajaxPoster = function (options) {
    let { url, data, success, error, complete } = options;
    if (!data) data = {};
    // if (window.csrf_token) data[window.csrf_token] = 1;
    try {
        return $.ajax(url, {
            type: 'POST',
            data: data,
            success: success ? success : () => {},
            error: error ? error : () => {},
            complete: complete ? complete : () => {},
        });
    } catch (e) {
        if (error) {
            error(e);
        }
    }
};

export default (function () {
    const { languagePath } = FJConfig;

    return {
        /**
         * @description 返回本地化数据
         * @param {string} tag
         * @return {Promise<any>}
         */
        fetchLanguage: async function (tag) {
            const url = `${languagePath}local.${tag}.json`;
            const res = await fetch(url);
            return await res.json();
        },

        /**
         * @description 举报
         * @returns {Promise<unknown>}
         */
        report(data) {
            return new Promise((resolve, reject) => {
                ajaxPoster({
                    url: '/cloudshare/report',
                    data: {
                        ...data,
                    },
                    success: response => {
                        if (response && response.code === 200) {
                            resolve(response.data);
                        } else {
                            reject(response.data);
                        }
                    },
                    error: () => {
                        reject(new Error('error in report!'));
                    },
                });
            });
        },
        getArticle: async function (type = 'video-editor', locale) {
            const res = await fetch(`${FJConfig.serverPath}/pages/get-article?local=${locale}&page=${type}`);
            return await res.json();
        },
        getRelated: async function (name) {
            const res = await fetch(`${FJConfig.serverPath}/scene/get-related?type=${name}`);
            return await res.json();
        },
        getHomeTempalte: async function (locale) {
            const res = await fetch(`${FJConfig.serverPath}/get-home-template?language=${locale}`);
            return await res.json();
        },
        searchTemplate: async function (keyword) {
            const res = await fetch(`https://static.flexclip.com/template/search-by-tools?keyword=${keyword}`);
            return await res.json();
        },

        // 获取页面json数据，用于页面渲染
        getPagePageJsonData: async function (url, data) {
            let newUrl = FJConfig.serverPath + url + '?';

            if (data) {
                for (let i in data) {
                    newUrl += `${i}=${data[i]}&`;
                }
            }

            try {
                const res = await fetch(newUrl);
                return await res.json();
            } catch (error) {
                console.log(error);
            }
        },

        getPageViewData: async function (id, language) {
            const res = await fetch(`${FJConfig.goApiPath}/PCT/findPageContent?ID=${id}&page_language=${language}`);
            // const res = await fetch(`http://10.0.30.26:8889/PCT/findPageContent?ID=${id}&page_language=${language}`);
            const { code, msg, data } = await res.json();
            if (code === 0) {
                return data.rePCT;
            } else {
                throw new Error(msg || code);
            }
        },

        getOverlaysData: async function () {
            const res = await fetch('http://localhost:3000/data/overlays.json');
            const { code, msg, data } = await res.json();
            if (Array.isArray(data)) {
                return data;
            } else {
                throw new Error(msg || code);
            }
        },

        numberDesc: async function (id, language) {
            const res = await fetch(`${FJConfig.goApiPath}/api/PCT/findPageContent?ID=${id}&page_language=${language}`);
            // const res = await fetch(`http://10.0.30.26:8889/PCT/findPageContent?ID=${id}&page_language=${language}`);
            const { code, msg, data } = await res.json();
            if (code === 0) {
                return data.rePCT.contents;
            } else {
                throw new Error(msg || code);
            }
        },
        getCustomPageIds: async function () {
            const res = await fetch(`${FJConfig.serverPath}/get-custom-page-ids`);
            const { code, msg, data } = await res.json();
            if (code === 200) {
                return data;
            } else {
                throw new Error(msg || code);
            }
        },
        getPageViewRenderData: async function (id) {
            // const res = await fetch(`https://admin.smartvideomaker.com/api/PCT/findPageContent?ID=${id}`);
            const res = await fetch(`${FJConfig.serverPath}/render-page-view?id=${id}`);
            const { code, msg, data } = await res.json();
            if (code === 200) {
                return data;
            } else {
                throw new Error(msg || code);
            }
        },
        // 提交feedback数据
        submitFeedbackForm(data, callback, errCallback) {
            let timeout15 = null;
            const start = () => {
                FJCaptcha.beginRecaptcha(
                    option => {
                        submitData({
                            ...data,
                            ...option,
                        });

                        try {
                            clearTimeout(timeout15);
                            timeout15 = null;
                        } catch (e) {
                            console.log(e);
                        }
                    },
                    err => {
                        try {
                            clearTimeout(timeout15);
                            timeout15 = null;
                            errCallback && errCallback(err);
                        } catch {
                            errCallback && errCallback(err);
                        }
                    },
                );
            };

            // 埋点-数据来源上传
            const __doStatistics = () => {
                FJUtil.ealog('feedback submit click', {
                    entrance: data['project_id'] ? 'help' : 'contact us',
                    'error account': data.email,
                });
            };

            function submitData(data) {
                let formData = new FormData();

                for (let key in data) {
                    formData.append(key, data[key]);
                }
                fetch(FJConfig.serverPath, {
                    method: 'post',
                    body: formData,
                })
                    .then(async res => {
                        const data = await res.json();
                        callback(data);
                        __doStatistics(res);
                    })
                    .catch(async err => {
                        const data = await err.json();
                        callback(data);
                    });
            }

            // 做防抖处理
            try {
                timeout15 = setTimeout(() => {
                    clearTimeout(timeout15);
                    timeout15 = null;
                    start();
                }, 0);
            } catch (e) {
                console.log(e);
            }
        },

        // 提交教育页学生认证数据
        submitEducationStudentData: async function (data) {
            const formData = new FormData();
            for (let key in data) {
                formData.append(key, data[key]);
            }
            try {
                const res = await fetch(`${FJConfig.serverPath}/upload-education-data`, {
                    method: 'POST',
                    body: formData,
                });
                return await res.json();
            } catch (error) {
                throw new Error(error);
            }
        },

        getAboutPageNewsData: async function (lang) {
            const res = await fetch(`${FJConfig.serverPath}/company/get-about-data?lang=${lang}`);
            const { code, msg, data } = await res.json();
            if (code === 200) {
                return data;
            } else {
                throw new Error(msg || code);
            }
        },

        getOnlineVideoToolsList: async function (lang) {
            const res = await fetch(`${FJConfig.serverPath}/?option=com_fj_pages&task=tools.getToolsList&lang=${lang}`);
            const { code, msg, data } = await res.json();
            if (code === 200) {
                return data;
            } else {
                throw new Error(msg || code);
            }
        },

        getCreatePageData: async function (theme, lang) {
            const res = await fetch(`${FJConfig.serverPath}/theme/get-theme-page-data?theme=${theme}&lang=${lang}`);
            const { code, msg, data } = await res.json();
            if (code === 200) {
                return data;
            } else {
                throw new Error(msg || code);
            }
        },

        getCreateRelatedData: async function (cate, pageName, lang) {
            const res = await fetch(
                `${FJConfig.serverPath}/page/get-related?cate=${cate}&current_page=${pageName}&page_language=${lang}`,
            );
            const { code, msg, data } = await res.json();
            if (code === 200) {
                return data;
            } else {
                return [];
            }
        },

        getTemplateData: async function (keyword, language) {
            const res = await fetch(
                `${FJConfig.templateSearchPath}/template/search?v1=1&keyword=${keyword}&language=${language}&record=0&has_editor=0`,
            );
            const { code, msg, data } = await res.json();
            if (code === 200) {
                return data;
            } else {
                return [];
            }
        },

        getCreateMoreTemplate: function (data, successFun, errorFun) {
            fetch(
                `${FJConfig.serverPath}/theme/get-template?theme=${data.theme}&page=${data.page}&lang=${data.lang}`,
            ).then(
                async r => {
                    const { code, msg, data } = await r.json();
                    if (code === 200) {
                        successFun({ code, msg, data });
                    } else {
                        errorFun({ code, msg, data });
                    }
                },
                async err => {
                    try {
                        const { code, msg, data } = await err.json();
                        errorFun({ code, msg, data });
                    } catch (e) {
                        errorFun(e);
                    }
                },
            );
        },

        // 获取用户评论
        getCommentData: async function (language) {
            const res = await fetch(`${FJConfig.serverPath}/get-home-comment?lang=${language}`);
            const { code, msg, data } = await res.json();
            if (code === 200) {
                return data;
            } else {
                return [];
            }
        },

        // 获取public中的json文件，用于本地测试
        getPublicJSON: async function (JSONName) {
            // const url = 'https://www.smartvideomaker.com';
            // const url = 'http://localhost:3000';
            const url =
                process.env.NEXT_PUBLIC_SERVER_TAG_NODE === 'test'
                    ? 'https://www.smartvideomaker.com/web-view/public'
                    : 'http://localhost:3000';
            const res = await fetch(`${url}/${JSONName}`);
            return await res.json();
        },

        // 获取compare（Flexclip与其他产品的比较）
        getCompareData: async function (name, language) {
            const res = await fetch(
                `${FJConfig.serverPath}/pages/get-compare-data?name=${name}&compare_language=${language}`,
            );
            const { code, msg, data } = await res.json();
            if (code === 200) {
                return data;
            } else {
                return [];
            }
        },

        getTextToImgJson: async function (type) {
            const res = await fetch(
                `${FJConfig.staticResourceServerAddress}/pages/ai-text-to-img/ai-text-to-img-${type}.json?v=${FJConfig.version}`,
            );

            return await res.json();
        },

        getAIVideoGeneratorPresetJson: async function () {
            const res = await fetch(
                `${FJConfig.staticResourceServerAddress}/pages/ai-video-generator/ai-video-generator-presets.json?v=${FJConfig.version}`,
            );

            const data = await res.json();
            const list = data.list;
            const tempList = [];
            list.forEach(item => {
                tempList.push({
                    category: item.category,
                    list: item.list.map(inner => {
                        return {
                            videoUrl: `${FJConfig.staticResourceServerAddress}/pages/ai-video-generator/${inner.videoUrl}`,
                            prompt: inner.prompt,
                            imageUrl:
                                `${FJConfig.staticResourceServerAddress}/pages/ai-video-generator/thumbnail/${inner.imageUrl}`,
                            useImgUrl:
                                inner.type === 'IV'
                                    ? `${FJConfig.staticResourceServerAddress}/pages/ai-video-generator/${inner.videoUrl}`.replace(
                                          '.mp4',
                                          '.webp',
                                      )
                                    : null,
                            type: inner.type,
                            ratio: inner.ratio,
                        };
                    }),
                });
            });

            return tempList;
        },

        getAIMusicGeneratorPresetJson: async function () {
            const res = await fetch(
                `${FJConfig.staticResourceServerAddress}/pages/next/ai-music-generator/presetList.json?v=${FJConfig.version}`,
            );

            const { list: presetList } = await res.json();

            const _shuffleArray = array => {
                for (let i = array.length - 1; i > 0; i--) {
                    // Generate a random index j such that 0 <= j <= i
                    const j = Math.floor(Math.random() * (i + 1));
                    // Swap elements at indexes i and j
                    [array[i], array[j]] = [array[j], array[i]];
                }
                return array;
            };

            const usePresetList = presetList.map(item => {
                return {
                    ...item,
                    coverUrl:
                        `${FJConfig.staticResourceServerAddress}/pages/next/ai-music-generator/image/` +
                        item.fileName.replace('.mp3', '.png'),
                    audioUrl:
                        `${FJConfig.staticResourceServerAddress}/pages/next/ai-music-generator/audio/` + item.fileName,
                    jumpAudioUrl: '/app/ai-tools/resource/AIAudio/audio/' + item.fileName,
                    useStyle: item.style.toLowerCase().replaceAll(' ', '-').replaceAll(',', ''),
                };
            });

            return _shuffleArray(usePresetList);
        },

        getTextToImgBannerJson: async function () {
            const res = await fetch(
                `${FJConfig.staticResourceServerAddress}/pages/ai-text-to-img/ai-text-to-img.tpl.json?v=${FJConfig.version}`,
            );

            return await res.json();
        },

        checkLogin() {
            return new Promise((resolve, reject) => {
                ajaxPoster({
                    url: FJConfig.serverPath + '/user/checkState',
                    data: {},
                    success: data => {
                        resolve(data);
                    },
                    error: e => {
                        reject(e);
                    },
                });
            });
        },

        getAiCatalogInfo: async function (lang) {
            try {
                const res = await fetch(`${FJConfig.serverPath}/get-ai-directory?lang=${lang}`);
                const { code, data } = await res.json();
                if (code === 200) {
                    return data;
                } else {
                    return {};
                }
            } catch (error) {
                throw new Error(error);
            }
        },

        getAiMusicMakerPresetList: async function () {
            const res = await fetch(
                `${FJConfig.staticResourceServerAddress}/pages/next/ai-music-maker/preset.json?v=${FJConfig.version}`,
            );

            return await res.json();
        },
    };
})();
