const STATIC_RESOURCE_SERVER = 'https://resource.flexclip.com';
const SSR_RESOURCE_SERVER = 'https://www.smartvideomaker.com';
const TEST_GO_API_PATH = 'https://admin.smartvideomaker.com/api';
const RELEASE_GO_API_PATH = 'https://www.flexclip.com/api';
const RELEASE_TEMPLATE_API_PATH = 'https://app.flexclip.com/';
const version = '1.1.0.0.0';
const buildVersion = '1.1.0.0.0';
console.log('webVersion:::', buildVersion);

export default {
    version,
    buildVersion,
    serverPath:
        process.env.NEXT_PUBLIC_SERVER_TAG_NODE !== 'release'
            ? 'https://www.smartvideomaker.com'
            : 'https://www.flexclip.com',
    aiServerPath:
        process.env.NEXT_PUBLIC_SERVER_TAG_NODE !== 'release'
            ? 'https://www.smartvideomaker.com/ai/export'
            : 'https://www.flexclip.com/ai/export',
    aiPagePath: process.env.NEXT_PUBLIC_SERVER_TAG_NODE !== 'release' ? 'apply' : 'ai',
    isLocal: process.env.NEXT_PUBLIC_SERVER_TAG_NODE === 'local',
    isTest: process.env.NEXT_PUBLIC_SERVER_TAG_NODE !== 'release',
    languagePath: `${STATIC_RESOURCE_SERVER}/language/`,
    staticResourceServerAddress: STATIC_RESOURCE_SERVER,
    ssRResourceServerAddress:
        process.env.NEXT_PUBLIC_SERVER_TAG_NODE !== 'release' ? SSR_RESOURCE_SERVER : STATIC_RESOURCE_SERVER,
    swrAge: 30 * 24 * 60 * 60,
    goApiPath: process.env.NEXT_PUBLIC_SERVER_TAG_NODE === 'release' ? RELEASE_GO_API_PATH : TEST_GO_API_PATH,
    templateSearchPath:
        process.env.NEXT_PUBLIC_SERVER_TAG_NODE === 'release' ? RELEASE_TEMPLATE_API_PATH : SSR_RESOURCE_SERVER,
    S3_SERVER_HOST:
        process.env.NEXT_PUBLIC_SERVER_TAG_NODE !== 'release'
            ? 'https://www.smartvideomaker.com/'
            : 'https://www.flexclip.com/',
};
